import { React, useState as useState } from 'react';
import {
  ChakraProvider,
  Box,
  HStack,
  VStack,
  extendTheme,
  Image,
  Text,
  Flex,
  List,
  ListItem,
  ListIcon,
  Progress,
  Spacer,
  Button,
} from '@chakra-ui/react';

import { CheckCircleIcon, EmailIcon } from '@chakra-ui/icons';
import { SocialIcon } from 'react-social-icons';

import ru from './ru.json';
import en from './en.json';

const dictionaryList = { ru, en };

const data = lng => {
  return lng === 'ENG' ? en : ru;
};

const theme = extendTheme({
  textStyles: {
    h1: {
      font: 'Roboto+Slab, serif',
      fontSize: ['48px', '72px'],
      fontWeight: 'bold',
      lineHeight: '110%',
      letterSpacing: '-2%',
    },
  },
  colors: {
    brand: {
      100: '#ECE3E1',
      200: '#4C4859',
      300: '#F0EFF1',
    },
  },
  fonts: {
    body: 'Roboto, sans-serif',
  },
  defaultProps: {
    variant: 'smooth',
  },
  breakpoints: {
    md: '768px',
    lg: '960px',
  },
});

const App = () => {
  const [userLanguage, setUserLanguage] = useState('RUS');

  const LanguageSelector = () => {
    const selectHandler = () => {
      setUserLanguage(userLanguage === 'RUS' ? 'ENG' : 'RUS');
    };
    return (
      <Button
        colorScheme="brand.200"
        variant="ghost"
        onClick={selectHandler}
        _focus={{
          color: 'brand.300',
          borderColor: 'brand.300',
          outline: 'none',
        }}
      >
        {userLanguage === 'RUS' ? 'ENG' : 'RUS'}
      </Button>
    );
  };

  const Skill = props => {
    return (
      <HStack pb={1}>
        <Box w="20%">{props.name}</Box>
        <Box w="70%">
          <Progress
            colorScheme="orange"
            size="xs"
            value={props.value}
            max={100}
          />
        </Box>
      </HStack>
    );
  };

  return (
    <ChakraProvider theme={theme}>
      <Box bg="white" p={4}>
        <Box bg="brand.300">
          <Flex>
            <Box
              display={{ base: 'none', md: 'block' }}
              w="w=20%"
              bg="brand.200"
              p={4}
            >
              <Image borderRadius="full" src="img/ava.png" alt="" />
            </Box>
            <Box pl={8} pt={3}>
              {data(userLanguage).topDescription.map(item => (
                <Text pb={1}>
                  <b>{item}</b>
                </Text>
              ))}
            </Box>
            <Spacer />
            <VStack mb="8px" pl={8}>
              <LanguageSelector />
              <Spacer />
              <HStack>
                <SocialIcon url="mailto:ruslan.leskov@gmail.com" />
                <SocialIcon network="telegram" url="https://t.me/rleskov" />
              </HStack>
            </VStack>
          </Flex>
        </Box>
        <Box
          bg="brand.100"
          p={8}
          borderTopWidth="2px"
          borderBottomWidth="2px"
          borderColor="brand.200"
        >
          <Box>
            <Text fontFamily="Roboto Slab" fontSize="24px">
              <b>{data(userLanguage).firstName}</b>{' '}
              {data(userLanguage).lastName}
            </Text>
            <Text fontFamily="Roboto Slab" fontSize="20px">
              {data(userLanguage).title}
            </Text>
            {/* <HStack mt="8px">
              <SocialIcon url="mailto:ruslan.leskov@gmail.com" />
              <SocialIcon network="telegram" url="https://t.me/rleskov" />
              </HStack> */}
            {/* 
            <Spacer />
            <Box w="8%">
              <SocialIcon url="mailto:ruslan.leskov@gmail.com" />
              <SocialIcon url="https://www.facebook.com/ruslan.leskov" />{' '} 
            </Box>
            <Spacer />
            <Box w="10%">
              <SocialIcon network="telegram" url="https://t.me/rleskov" />
            </Box>
            */}
          </Box>
        </Box>
        <Box bg="white">
          <Box display={{ base: 'block', md: 'flex' }}>
            <Box w={{ base: '100%', md: '50%' }} pl={8}>
              <Text fontFamily="Roboto Slab" fontSize="24px" pb={2} pt={4}>
                {data(userLanguage).skills}
              </Text>
              <Text>{'{'}</Text>
              <Text pl={4} color="orange.500" fontWeight="bold">
                {data(userLanguage).languages}
              </Text>
              <Box pl={8}>
                <Skill name="Go" value={92} />
                <Skill name="C" value={96} />
                <Skill name="C++" value={88} />
                <Skill name="C#" value={90} />
                <Skill name="Java/Android" value={84} />
                <Skill name="Java/Spring" value={79} />
                <Skill name="R" value={87} />
                <Skill name="Python" value={92} />
                <Skill name="Dart" value={81} />
              </Box>
              <Text>{'},{'}</Text>
              <Text pl={4} color="orange.500" fontWeight="bold">
                {data(userLanguage).web}
              </Text>
              <Text pl={8} pb={1}>
                <b>Frontend:</b> [ JS, React, Angular, C# ],
              </Text>
              <Text pl={8} pb={1}>
                <b>Backend:</b> [ Go, C#, Python, С++, Java ]
              </Text>
              <Text>{'},{'}</Text>
              <Text pl={4} color="orange.500" fontWeight="bold">
                {data(userLanguage).db}
              </Text>
              <Text pl={8} pb={1}>
                <b>SQL:</b> [ MS SQL Server, PostgreSQL, Oracle, MySQL ],
              </Text>
              <Text pl={8} pb={1}>
                <b>NoSQL:</b> [ MongoDB, BerkleyDB, Redis, CouchDB ],
              </Text>
              <Text pl={8} pb={1}>
                <b>Cloud:</b> [ Azure CosmosDB, CosmosDB/MondoDB API, DynamoDB ]
              </Text>
              <Text>{'}'}</Text>
            </Box>
            <Box
              w={{ base: '100%', md: '50%' }}
              bg="brand.200"
              color="white"
              p={4}
              pl={8}
              flex="1"
              pt={2}
            >
              <Text fontFamily="Roboto Slab" fontSize="24px" pb={2}>
                {data(userLanguage).projects}
              </Text>
              <List>
                {data(userLanguage).projectList.map(item => (
                  <ListItem pt={3}>
                    <ListIcon as={CheckCircleIcon} color="orange.500" />
                    {item}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default App;
